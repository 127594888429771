:root {
  --greyLight: #aaa;
  --borderRadius: 8px;
}

body {
  user-select: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.fake-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.app {
  display: flex;
  max-width: 1440px;
  margin: 0 auto; 
}

.columns_wrap {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.columns {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@media screen and (max-width: 1200px) {
  .columns_wrap {
    grid-template-columns: 1fr 2fr;
  }
}

@media screen and (max-width: 900px) {
  .columns_wrap {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .columns_wrap {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.column {
  margin: 1px;
  background-color: rgb(221, 221, 221);
}
   
.task-list::-webkit-scrollbar {
  width: 1em;
}

.task-list::-webkit-scrollbar-track {
  background-color: var(--greyLight);
  borderradius: var(--borderRadius);
}

.task-list::-webkit-scrollbar-thumb {
  background-color: rgb(139, 139, 139);
}

.item {
  user-select: none;
  border-right: 1px solid #aaa!important;
}
.item:hover {
  background-color: rgba(0, 0, 0, 0.04)!important;
}

.item.is-dragging {
  color: #fff!important;
  background-color: #a0a0ff!important;
}

.item span::first-letter {
  text-transform: uppercase;
}